import _ from 'lodash'
import { calcDominatedValueInArray } from '../utils'
import { getDefaultLabel } from '../fields/utils'
import { FieldLayoutProp } from '../layout-panel/utils'
import { ResponsiveLayout } from '@wix/platform-editor-sdk'
import { fieldsStore } from '../preset/fields/fields-store'
import { FIELD_COMPONENT_TYPES } from '@wix/forms-common'

// common layout group is not always from the same type, so we enforce it to be
// each group has one dominated type, that some properties will use this callbacks to align to it
const propsTransformationToCommonLayout: {
  [key: string]: {
    fromCommonLayoutToValue?: (propValue: PROPERTIES) => PROPERTIES
    fromValueToCommonLayout?: (propValue: PROPERTIES) => PROPERTIES
  }
} = {
  alignment: {
    fromCommonLayoutToValue: (value: PROP_TEXT_ALIGNMENT): PROP_ALIGNMENT =>
      value === 'center' || value === 'left' ? 'left' : 'right',
    fromValueToCommonLayout: (value: PROP_ALIGNMENT): PROP_TEXT_ALIGNMENT =>
      value === 'left' ? 'left' : 'right',
  },
  direction: {
    fromCommonLayoutToValue: (value: PROP_TEXT_ALIGNMENT): PROP_DIRECTION =>
      value === 'center' || value === 'left' ? 'ltr' : 'rtl',
    fromValueToCommonLayout: (value: PROP_DIRECTION): PROP_TEXT_ALIGNMENT =>
      value === 'ltr' ? 'left' : 'right',
  },
  layout: {
    fromValueToCommonLayout: (value: PROP_LAYOUT): PROP_LABEL_POSITION =>
      value === 'vertical' ? 'top' : 'bottom',
    fromCommonLayoutToValue: (value: PROP_LABEL_POSITION): PROP_LAYOUT =>
      value === 'top' || value === 'side' ? 'vertical' : 'horizontal',
  },
  buttonAlignment: {
    fromValueToCommonLayout: (value: PROP_DIRECTION): PROP_LABEL_POSITION =>
      value === 'ltr' ? 'top' : 'bottom',
    fromCommonLayoutToValue: (value: PROP_LABEL_POSITION): PROP_DIRECTION =>
      value === 'top' || value === 'side' ? 'ltr' : 'rtl',
  },
  shapeSpacing: {
    fromCommonLayoutToValue: (spacing: number): number => Math.max(1, Math.min(spacing, 50)),
  },
  buttonSize: {
    fromCommonLayoutToValue: (size: number): number => Math.max(6, Math.min(size / 1.5, 100)),
    fromValueToCommonLayout: (size: number) => size * 1.5,
  },
  shapeSize: {
    fromCommonLayoutToValue: (size: number) => Math.max(15, Math.min(size, 100)),
  },
  margin: {
    fromCommonLayoutToValue: (size: number) => Math.max(1, Math.min(size, 30)),
  },
}

const similarProps: string[][] = [
  ['textAlignment', 'labelAlignment', 'filesAlignment', 'alignment', 'direction'], // dominated type: textAlignment
  ['layout', 'labelPosition', 'buttonAlignment'], // dominated type: labelPosition
  ['textPadding', 'spacing', 'margin'], // dominated type: number (limits: 0 - 100 , 0 - 100, 1 - 30)
  ['labelPadding', 'titlePaddingStart'], // dominated type: number (limits: 0 - 100, 0 - 100)
  ['labelMargin', 'shapeSpacing', 'buttonsMargin', 'titleMarginBottom'], // dominated type: number (limits: 0 - 100, 1 - 50 , 0 - 100, 0 - 100)
  ['buttonSize', 'shapeSize'], // dominated type: number (limits: 6 - 100, 15 - 100),
]

const similarComponentTypes: FIELD_COMPONENT_TYPES[][] = [
  [FIELD_COMPONENT_TYPES.RADIO_GROUP, FIELD_COMPONENT_TYPES.CHECKBOX_GROUP],
  [
    FIELD_COMPONENT_TYPES.TEXT_INPUT,
    FIELD_COMPONENT_TYPES.DATE_PICKER,
    FIELD_COMPONENT_TYPES.COMBOBOX,
  ],
]

const getSimilarPropsValues = (fields: FormField[], similarPropsKeys: string[]): PROPERTIES[] => {
  const similarPropsValues = fields.map(({ fieldType, ...fieldProperties }) => {
    const fieldPropsInSimilarPropsKeys: string[] = getFieldPropsInSimilarPropsKeys(
      fieldType,
      similarPropsKeys,
    )
    return fieldPropsInSimilarPropsKeys
      .map(prop => {
        const fromValueToCommonLayout = _.get(
          propsTransformationToCommonLayout,
          [prop, 'fromValueToCommonLayout'],
          _.identity,
        )
        return fromValueToCommonLayout(fieldProperties[prop])
      })
      .filter(value => !(value === undefined))
  })
  return _.flatMap(similarPropsValues)
}

const getSimilarPropsValue = (fields: FormField[]) =>
  similarProps.map(props => calcDominatedValueInArray(getSimilarPropsValues(fields, props)))

const getPropIndex = (propKey: string): number =>
  _.findIndex(similarProps, props => _.includes(props, propKey))

const getFieldPropsInSimilarPropsKeys = (fieldType: FieldPreset, similarPropsKeys: string[]) =>
  _.intersection(
    _.get(fieldsStore.allFieldsData[fieldType], 'layoutComponentProps', []),
    similarPropsKeys,
  )

const getCommonFieldProps = (
  fields: FormField[],
  propsToCheck: string[],
  fieldProps: any,
  revokedCommonLayoutTransformationProps: any
) => {
  const similarPropsValue = getSimilarPropsValue(fields)

  const propsFromFields = propsToCheck.reduce((acc, propKey) => {
    const propIndex = getPropIndex(propKey)

    const transformer = getTransformer(revokedCommonLayoutTransformationProps, propKey)

    acc[propKey] =
      similarPropsValue[propIndex] !== undefined
        ? transformer(similarPropsValue[propIndex])
        : _.get(fieldProps, propKey)
    return acc
  }, {})
  return { ...fieldProps, ...propsFromFields }
}

const getFieldPropsFromSimilarField = (
  similarField: FormField,
  propsToCheck: string[],
  fieldProps: any,
) => {
  const propsFromSimilarFields = propsToCheck.reduce((acc, propKey) => {
    acc[propKey] = similarField[propKey]
    return acc
  }, {})
  if (similarField.inputHeight) {
    propsFromSimilarFields['inputHeight'] = similarField.inputHeight
  }
  return { ...fieldProps, ...propsFromSimilarFields }
}

const getFieldProps = (
  propsToCheck,
  fields: FormField[],
  similarField: FormField | undefined,
  fieldProps: any,
  revokedCommonLayoutTransformationProps: any[]
) => {
  if (_.isEmpty(propsToCheck)) {
    return fieldProps
  }
  return similarField
    ? getFieldPropsFromSimilarField(similarField, propsToCheck, fieldProps)
    : getCommonFieldProps(fields, propsToCheck, fieldProps, revokedCommonLayoutTransformationProps)
}

const getFieldLabel = (
  similarField: FormField | undefined,
  fieldType: FieldPreset,
  fieldData,
): string | undefined => {
  const showLabel = _.get(similarField, 'showLabel') || (!similarField && fieldData.label)
  return showLabel ? getDefaultLabel({ fieldType, ...fieldData }) : undefined
}

const getFieldLayout = (similarField: FormField | undefined, fieldComponent) => {
  const width = _.get(similarField, 'width')
  const height = _.get(similarField, 'height')
  return width !== undefined && height !== undefined
    ? _.assign({}, fieldComponent.layout, {
        width,
        height,
      })
    : fieldComponent.layout
}

const getFieldLayoutResponsive = (
  similarField: FormField | undefined,
  fieldComponent,
): ResponsiveLayout => {
  const similarComponentLayout = _.get(similarField, 'layoutResponsive.componentLayouts[0]')
  const similarItemLayouts = _.get(similarField, 'layoutResponsive.itemLayouts[0]')
  return similarComponentLayout
    ? {
        ...fieldComponent.layoutResponsive,
        componentLayouts: [similarComponentLayout],
        itemLayouts: [
          {
            ...similarItemLayouts,
            order: fieldComponent.layoutResponsive.itemLayouts[0].order,
            id: '',
          },
        ],
      }
    : fieldComponent.layoutResponsive
}

const getSimilarComponentsByType = (
  componentType: FIELD_COMPONENT_TYPES,
  fields: FormField[],
): FormField | undefined => {
  const exactFields = fields.filter(field => field.componentType === componentType)
  if (exactFields.length) {
    return _.last(exactFields)
  }
  const componentTypeGroup = _.find(similarComponentTypes, group =>
    _.includes(group, componentType),
  )
  const similarFields = fields.filter(field => _.includes(componentTypeGroup, field.componentType))
  return _.last(similarFields)
}

export const createFieldWithMostCommonLayout = (
  fieldType: FieldPreset,
  fields: FormField[],
  fieldComponent,
  isResponsive: boolean = false,
) => {
  const {
    properties: { componentType },
    revokedCommonLayoutTransformationProps,
    layoutComponentProps: propsToCheck,
  } = fieldsStore.allFieldsData[fieldType]

  const similarField = getSimilarComponentsByType(componentType, fields)

  const newField = _.cloneDeep(fieldComponent)
  if (isResponsive) {
    newField.layoutResponsive = getFieldLayoutResponsive(similarField, fieldComponent)
  } else {
    newField.layout = getFieldLayout(similarField, fieldComponent)
  }

  newField.data.label = getFieldLabel(similarField, fieldType, fieldComponent.data)

  newField.props = getFieldProps(
    propsToCheck,
    fields,
    similarField,
    fieldComponent.props,
    revokedCommonLayoutTransformationProps
  )

  return newField
}

export const getComponentTypeLayoutProp = (
  fieldType: FieldPreset,
  propKey: string,
  propValue: PROPERTIES,
): FieldLayoutProp => {
  const propIndex = getPropIndex(propKey)
  const fieldPropsInSimilarPropsKeys = getFieldPropsInSimilarPropsKeys(
    fieldType,
    similarProps[propIndex],
  )
  const { revokedCommonLayoutTransformationProps } = fieldsStore.allFieldsData[fieldType]

  return fieldPropsInSimilarPropsKeys.length
    ? fieldPropsInSimilarPropsKeys.reduce((acc, propKey) => {
        const transformer = getTransformer(revokedCommonLayoutTransformationProps, propKey)
        acc[propKey] = transformer(propValue)
        return acc
      }, {})
    : null
}

const getTransformer = (revokedCommonLayoutTransformationProps, propKey) => {
  const useCommonLayoutTransformer = !_.includes(revokedCommonLayoutTransformationProps, propKey)
  const transformerToCommonLayout =
    useCommonLayoutTransformer &&
    _.get(propsTransformationToCommonLayout[propKey], 'fromCommonLayoutToValue')
  return transformerToCommonLayout || _.identity
}
