import _ from 'lodash'
import { createFormContainerManifest } from './box-manifest'
import {
  FIELDS_ROLES,
  ROLE_DOWNLOAD_MESSAGE,
  ROLE_FORM,
  ROLE_MESSAGE,
  ROLE_SUBMIT_BUTTON,
  ROLE_TITLE,
  ROLE_PREVIOUS_BUTTON,
  ROLE_NEXT_BUTTON,
  REGISTRATION_FIELDS_ROLES,
} from '../../../constants/roles'
import { createSubmitButtonManifest } from './submit-button-manifest'
import {
  createHiddenMessageManifest,
  createDownloadMessageManifest,
} from './hidden-message-manifest'
import { createTitleManifest } from './title-manifest'
import { AppStateBuilder, AppStateObject } from '../app-state/app-state-builder'
import { FormPlugin } from '@wix/forms-common'
import { createMultiStepManifest } from './multi-step-manifest'
import { createFirstTimeExperienceManifest } from './first-time-experience-manifest'
import { stepButtonManifest } from './step-button-manifest'
import { createFieldsManifests } from '../preset/fields/field-types-data'

export const OLD_CONTROLLER_TYPE = 'singlePostController'

const ALWAYS_HIDE_CONTROLLER = 'NEVER'
const plugins = [
  FormPlugin.FORM_BUILDER,
  FormPlugin.GET_SUBSCRIBERS,
  FormPlugin.REGISTRATION_FORM,
  FormPlugin.MULTI_STEP_FORM,
]
const states = _.flatMap(plugins, plugin =>
  _.flatMap(
    [true, false],
    duplicatable =>
      new AppStateBuilder({
        duplicatable,
        plugins: [plugin],
      }),
  ),
).filter(x => x.toString())

const statesByPlugin: { [key in FormPlugin]?: AppStateBuilder[] } = plugins.reduce(
  (acc, plugin: FormPlugin) => {
    acc[plugin] = states.filter(appState => _.includes(appState.get().plugins, plugin))
    return acc
  },
  {},
)

export const getAppManifest = ({
  isTopPremium,
  isResponsive,
  withCorvidAPI,
  withoutAddGfpp,
  withMultiStepLayoutPanel,
}) => {
  const enhanceState = state => ({
    ...state,
    isTopPremium,
    isResponsive,
    withoutAddGfpp,
    withMultiStepLayoutPanel
  })

  const createFieldManifests = (controllerType: ControllerType | 'singlePostController') => {
    let filteredFieldsRoles = FIELDS_ROLES

    if (!_.includes(['registrationForm', OLD_CONTROLLER_TYPE], controllerType)) {
      // filter registration fields for unrelated controller types
      filteredFieldsRoles = _.difference(filteredFieldsRoles, REGISTRATION_FIELDS_ROLES)
    }

    const fieldsManifest = createFieldsManifests()

    return filteredFieldsRoles.reduce(
      (res, fieldRole) => ({
        ...res,
        [fieldRole]: fieldsManifest[fieldRole],
      }),
      {},
    )
  }

  const titleManifest = createTitleManifest()

  const createManifest = (
    appState: AppStateObject,
    controllerType: ControllerType | 'singlePostController',
  ) => {
    const state = enhanceState(appState)
    const isMultiStepForm = controllerType === 'multiStepForm'

    const { box, steps } = isMultiStepForm
      ? createMultiStepManifest(state)
      : createFormContainerManifest(state)

    let pluginsMainRoles = {}

    if (isMultiStepForm) {
      pluginsMainRoles = {
        [ROLE_PREVIOUS_BUTTON]: stepButtonManifest(ROLE_PREVIOUS_BUTTON),
        [ROLE_NEXT_BUTTON]: stepButtonManifest(ROLE_NEXT_BUTTON),
      }
    }

    return {
      visibility: ALWAYS_HIDE_CONTROLLER,
      ...box,
      connections: {
        [ROLE_FORM]: box,
        [ROLE_SUBMIT_BUTTON]: createSubmitButtonManifest(state, controllerType),
        [ROLE_MESSAGE]: createHiddenMessageManifest(state, controllerType),
        [ROLE_DOWNLOAD_MESSAGE]: createDownloadMessageManifest(),
        [ROLE_TITLE]: titleManifest,
        ...pluginsMainRoles,
        ...steps,
        ...createFieldManifests(controllerType),
      },
      ...createFirstTimeExperienceManifest(state, controllerType),
    }
  }

  const createDefaultStateWithStates = (
    states,
    controllerType: ControllerType | 'singlePostController',
  ) => {
    let defaultState = createManifest({}, controllerType)

    const controllerStates = {
      ...states.reduce((agg, state) => {
        const stateData = state.get()

        if (_.includes(stateData.plugins, FormPlugin.MULTI_STEP_FORM)) {
          // ignore multi step state for old controller (not valid state)
          if (controllerType === OLD_CONTROLLER_TYPE) {
            return agg
          }

          // ignore multi step state that isn't duplicatable (not valid state)
          if (!stateData.duplicatable) {
            return agg
          }
        }

        const stateManifest = createManifest(state.get(), controllerType)

        if (!stateData.duplicatable && controllerType !== 'singlePostController') {
          defaultState = _.merge({}, defaultState, stateManifest)
        }

        agg[state.toString()] = stateManifest
        return agg
      }, {}),
    }

    const wannaBeDefault = _.find(controllerStates, (_v, k) => !_.includes(k, 'duplicatable'))

    return {
      default: _.merge({}, defaultState, wannaBeDefault),
      ...controllerStates,
    }
  }

  const controllersStageDataAppManifest: ControllerManifest = {
    controllersStageData: {
      singlePostController: createDefaultStateWithStates(states, OLD_CONTROLLER_TYPE), //TODO: [PERFORMANCE] UPDATE CONTROLLER ON INIT AND REMOVE THIS CONTROLLER TYPE
      wixForms: createDefaultStateWithStates(statesByPlugin[FormPlugin.FORM_BUILDER], 'wixForms'),
      getSubscribers: createDefaultStateWithStates(
        statesByPlugin[FormPlugin.GET_SUBSCRIBERS],
        'getSubscribers',
      ),
      registrationForm: createDefaultStateWithStates(
        statesByPlugin[FormPlugin.REGISTRATION_FORM],
        'registrationForm',
      ),
      multiStepForm: createDefaultStateWithStates(
        statesByPlugin[FormPlugin.MULTI_STEP_FORM],
        'multiStepForm',
      ),
    },
  }

  const manifestExports: ExportsManifest = withCorvidAPI
    ? {
        exports: {
          registrationForm: createCorvidAPIManifest(),
          wixForms: createCorvidAPIManifest(),
          getSubscribers: createCorvidAPIManifest(),
          multiStepForm: createCorvidAPIManifest(),
        },
      }
    : {}

  return {
    ...reduceAppManifest(controllersStageDataAppManifest),
    ...manifestExports,
  }
}

const reduceAppManifest = appManifest => {
  const slimAppManifest = {
    controllersStageData: {},
  }

  _.forEach(appManifest.controllersStageData, (states, controllerType) => {
    let statesData = { default: {} }

    _.forEach(states, (stateData, state) => {
      if (state === 'default') {
        statesData.default = stateData
      } else {
        const diff = difference(stateData, appManifest.controllersStageData[controllerType].default)

        if (!_.isEmpty(diff)) {
          statesData[state] = diff
        }
      }
    })

    slimAppManifest.controllersStageData[controllerType] = { ...statesData }
  })

  return slimAppManifest
}

const difference = (object, base) => {
  const changes = (object, base) => {
    return _.omitBy(
      _.transform(object, (result: any, value, key) => {
        if (!_.isEqual(value, base[key])) {
          result[key] =
            _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value
        }
      }),
      value => !_.isBoolean(value) && _.isEmpty(value),
    )
  }

  return changes(object, base)
}

const createCorvidAPIManifest = () => ({
  eventHandlers: {
    onWixFormSubmitted: {
      type: 'wixFormSubmitEvent',
      description: 'An event that fires when a site visitor submits a form successfully.',
      params: [
        {
          name: 'handler',
          description: 'The handler of the submit event',
        },
      ],
    },
    onWixFormSubmittedError: {
      type: 'wixFormSubmitErrorEvent',
      description:
        'An event that fires when a site visitor was not able to submits a form successfully.',
      params: [
        {
          name: 'handler',
          description: 'The handler of the submit error event',
        },
      ],
    },
  },
  inherits: {},
})

// Maybe will be useful one day..
// const intersection = (object, base) => {
//   const changes = (object, base) => {
//     return _.omitBy(_.transform(object, (result: any, value, key) => {
// 			if (_.isEqual(value, base[key])) {
// 				result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
// 			}
// 		}), value => !_.isBoolean(value) && _.isEmpty(value));
//   }

//   return changes(object, base)
// }
