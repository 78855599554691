import { EditorPlatformApp } from '@wix/platform-editor-sdk'
import { editorAppMetaData } from './editor-app'

// i'm not connecting it at the moment to our exports
export const handleAction: EditorPlatformApp['handleAction'] = async ({ type }) => {
  switch (type) {
    case 'migrate': {
      const api = await editorAppMetaData.getCoreApi()
      api.interactionStarted('contact-form-migration')
      await api.addForm.convertAllContactForms()
      api.interactionEnded('contact-form-migration')
    }
    default:
      break
  }
}
