export const PAYMENT_STATUS = {
  UPGRADE_TO_ASCEND: 'upgrade-to-ascend',
  MISSING_PAYMENT_METHOD: 'missing-payment-method',
  CONNECTED: 'connected',
  GET_STARTED: 'get-started'
}

export const LEARN_MORE_HELP_ID = '817df5e6-fcbf-45e7-a18a-52e2dc53227b'

export const BI_ACTIONS = {
  OPEN_PAYMENT_OVERVIEW: 'Open Payment Overview',
  CHANGE_PAYMENT_METHOD: 'Change Payment Method',
  REMOVE_PAYMENT: 'Remove Payment from this site',
  REMOVE_PAYMENT_REMOVE: 'remove',
  REMOVE_PAYMENT_CANCEL: 'cancel',
  REMOVE_PAYMENT_DISMISS: 'dismiss',
}

export const DELETE_ALERT_HELP_ID = '25f55ee1-7d47-426d-a3d5-bd1567ac5b53'

