import _ from 'lodash'
import CoreApi from '../core-api'
import { ROLE_MESSAGE, ROLE_SUBMIT_BUTTON } from '../../../constants/roles'
import { RegistrationFieldPreset, FormPlugin } from '@wix/forms-common'
import translations from '../../../utils/translations'
import {
  CATEGORIES,
  DEFAULT_CATEGORIES,
} from '../../../panels/manage-fields-panel/constants/manage-fields-constants'
import { ExtendApi } from './extend-api'
import { REGISTRATION_FORM_FEEDBACK } from '../../../panels/form-settings-panel/constants/support-urls'
import { FOOTER_CTA_CLICKED } from './event-types'
import { ACTION_TYPE } from './action-types'
import { getFieldRenderConfigFields } from '../preset/fields/field-types-data'
import { fieldsStore, fieldsTypes } from '../preset/fields/fields-store'

// TODO: Move this to single point of configuration
const CrucialFieldPreset = {
  LOGIN_EMAIL: RegistrationFieldPreset.REGISTRATION_FORM_LOGIN_EMAIL,
  PASSWORD: RegistrationFieldPreset.REGISTRATION_FORM_PASSWORD,
  LINK_TO_LOGIN: RegistrationFieldPreset.REGISTRATION_FORM_LINK_TO_LOGIN,
}

export const REGISTRATION_FORM_CRUCIAL_FIELD_PRESET_VALUES: FieldPreset[] = _.values(
  CrucialFieldPreset,
)

const RegistrationFormApi = (coreApi: CoreApi): ExtendApi => {
  const getCrucialElements = async (
    componentRef: ComponentRef,
    componentConnection: ComponentConnection,
  ) => {
    const { controllerRef } = componentConnection

    const missingFields = await Promise.all([
      coreApi.isFieldMissingByRole(componentRef, ROLE_MESSAGE),
      coreApi.isFieldMissingByRole(componentRef, ROLE_SUBMIT_BUTTON), // TODO: Combine to single call
      coreApi.isMissingFieldByPreset(controllerRef, CrucialFieldPreset.LOGIN_EMAIL),
      coreApi.isMissingFieldByPreset(controllerRef, CrucialFieldPreset.PASSWORD),
      coreApi.isMissingFieldByPreset(controllerRef, CrucialFieldPreset.LINK_TO_LOGIN),
    ])

    return _.filter(missingFields)
  }

  const getNewFields = () => {
    return {
      add: [
        ..._.map(fieldsTypes(fieldsStore.registrationFields), (type: FieldPreset) => ({
          value: type,
          name: translations.t(`fieldTypes.${type}`),
          category: CATEGORIES.registration,
        })),
      ],
      remove: _.reduce(
        fieldsTypes(fieldsStore.recommendedFields),
        (acc, fieldType: FieldPreset) => {
          const fieldRenderConfig = getFieldRenderConfigFields(
            [FormPlugin.REGISTRATION_FORM],
            fieldType,
          )
          const shouldRemoveField =
            fieldRenderConfig.addFieldPanelData.category === DEFAULT_CATEGORIES.none

          return shouldRemoveField ? [...acc, fieldType] : acc
        },
        [],
      ),
    }
  }

  const onFooterCtaClicked = _eventPayload => {
    coreApi.managePanels.openMemberSignupPagePanel()
  }

  const onEvent = ({ eventType, eventPayload }) => {
    switch (eventType) {
      case FOOTER_CTA_CLICKED:
        onFooterCtaClicked(eventPayload)
        break
    }
  }

  return {
    onEvent,
    'settings.getCrucialElements': getCrucialElements,
    'fields.getNewFields': getNewFields,
    appConfig: async (): Promise<AppConfig> => {
      const baseUrl = await coreApi.getBusinessManagerUrl()

      return {
        features: {
          notifications: false,
          collection: false,
        },
        mainCTA: {
          actions: [
            {
              type: ACTION_TYPE.LINK,
              href: `${baseUrl}/member-permissions/members`,
              text: translations.t('formSettings.mainTab.registrationForm.mainActionButtonText'),
            },
          ],
        },
        secondaryCTA: {
          title: null,
          subtitle: null,
          cta: {
            type: ACTION_TYPE.CUSTOM,
            text: translations.t('formSettings.mainTab.footer.registrationForm.link'),
            href: null,
          },
        },
        content: {
          formNameInfo: translations.t('formSettings.settingsTab.registrationForm.info'),
          onSubmitMessage: translations.t('settings.errorMessage.default'),
        },
        feedbackUrl: REGISTRATION_FORM_FEEDBACK,
      }
    },
  }
}

export default RegistrationFormApi
