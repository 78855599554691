import _ from 'lodash'
import {
  ContactFormEnhancer,
  getTextInputComponent,
  getButtonComponent,
} from './contact-form-service'
import { COMPONENT_TYPES } from '@wix/forms-common'
import { getFontSize, getBorderSize } from './contact-form-style'

const getHeight = ({
  formField,
  lineHeight,
  numLines,
  fontMap,
}: {
  formField: RawComponentStructure
  lineHeight?: number
  numLines: number
  fontMap: any
}): number => {
  if (!formField) {
    return 0
  }
  let actualLineHeight = lineHeight
  if (!actualLineHeight) {
    const { size, lineHeight } = getFontSize(formField, fontMap)
    actualLineHeight = size * lineHeight
  }
  const borderSize = getBorderSize(formField)
  const padding = 5
  return Math.floor(actualLineHeight * numLines + borderSize * 2 + padding * 2)
}

export const getTextInputHeight = (
  convertedForm: RawComponentStructure,
  fontMap,
  lineHeight?: number,
) => {
  const textInputComponent = getTextInputComponent(convertedForm)
  return Math.max(
    getHeight({
      formField: textInputComponent,
      numLines: 1,
      fontMap,
      lineHeight,
    }),
    36,
  )
}

export const getMessageHeight = (
  convertedForm: RawComponentStructure,
  fontMap,
  lineHeight?: number,
) => {
  const textInputComponent = getTextInputComponent(convertedForm)
  return Math.max(
    getHeight({
      formField: textInputComponent,
      numLines: 2,
      fontMap,
      lineHeight,
    }),
    150,
  )
}

export const getButtonHeight = (convertedForm: RawComponentStructure, fontMap) => {
  const buttonComponent = getButtonComponent(convertedForm)
  return Math.max(getHeight({ formField: buttonComponent, numLines: 1, fontMap }), 45)
}

const fixFullWidthButtonLayout = (
  contactForm: DynamicContactForm,
  convertedForm: RawComponentStructure,
  fontMap,
) => {
  const marginRight = 17
  const inputMarginBottom = 12
  const textAreaMarginBottom = 12
  const submitButtonMarginBottom = 10
  const inputTextPadding = 5
  const submitMessageHeight = 12
  const inputFieldHeight = getTextInputHeight(convertedForm, fontMap)
  const messageFieldHeight = getMessageHeight(convertedForm, fontMap)
  const submitButtonHeight = getButtonHeight(convertedForm, fontMap)
  let currentY = 0

  _.forEach(convertedForm.components, (field, idx) => {
    const handleComponentLayout = ({
      bottomMargin,
      height,
      textPadding,
      inputHeight,
    }: {
      bottomMargin: number
      height: number
      textPadding?: number
      inputHeight?: number
    }) => {
      if (_.includes([0, 1], idx)) {
        const firstBlockFieldWidth = (contactForm.layout.width - marginRight) / 2
        const isRTL = contactForm.data.textDirection === 'right'
        const secondElementX = firstBlockFieldWidth + marginRight
        if (idx === 0) {
          field.layout = {
            x: isRTL ? secondElementX : 0,
            y: currentY,
            width: firstBlockFieldWidth,
            height,
          }
        }

        if (idx === 1) {
          field.layout = {
            x: isRTL ? 0 : secondElementX,
            y: currentY,
            width: firstBlockFieldWidth,
            height,
          }

          currentY = currentY + height + bottomMargin
        }
      } else {
        field.layout = { x: 0, y: currentY, width: contactForm.layout.width, height }
        currentY = currentY + height + bottomMargin
      }

      if (textPadding !== undefined) {
        field.props.textPadding = textPadding
      }

      if (inputHeight !== undefined) {
        field.props.inputHeight = inputHeight
      }
    }

    switch (field.componentType) {
      case COMPONENT_TYPES.TEXT_INPUT:
        handleComponentLayout({
          bottomMargin: inputMarginBottom,
          height: inputFieldHeight,
          textPadding: inputTextPadding,
        })
        break
      case COMPONENT_TYPES.TEXT_AREA:
        handleComponentLayout({
          bottomMargin: textAreaMarginBottom,
          height: messageFieldHeight,
          inputHeight: messageFieldHeight,
          textPadding: inputTextPadding,
        })
        break
      case COMPONENT_TYPES.SITE_BUTTON:
        handleComponentLayout({
          bottomMargin: submitButtonMarginBottom,
          height: submitButtonHeight,
        })
        break
      case COMPONENT_TYPES.TEXT:
        handleComponentLayout({
          bottomMargin: 0,
          height: submitMessageHeight,
        })
        break
    }
  })

  return convertedForm
}

export const enhanceFormWithDesktopStructure: ContactFormEnhancer = (
  contactForm: DynamicContactForm,
  convertedForm: RawComponentStructure,
  fontMap,
) => {
  switch (contactForm.skin) {
    case 'contactform.FullWidthButtonSkin':
      return fixFullWidthButtonLayout(contactForm, convertedForm, fontMap)
    default:
      return convertedForm
  }
}
