import { FIELDS, ROLE_FORM } from '../../../../../../constants/roles'
import { RegistrationFieldPreset, FIELD_COMPONENT_TYPES, FormPlugin } from '@wix/forms-common'
import { iconNames } from '../../icons/types'
import { IGeneralField } from '../../general-field'
import { CATEGORIES } from '../../../../../../panels/manage-fields-panel/constants/manage-fields-constants'

export class LinkToLogin extends IGeneralField {
  public get customFields() {
    return []
  }

  public get allowOnlyOnce() {
    return true
  }

  public get crmType() {
    return null
  }

  protected get crmLabel() {
    return null
  }

  public get disableCollectionSync() {
    return true
  }

  protected get icon() {
    return iconNames.link
  }

  protected get renderConfig() {
    return {
      [FormPlugin.REGISTRATION_FORM]: {
        isMandatory: true,
        addFieldPanelData: { category: CATEGORIES.registration },
      },
    }
  }

  public get fieldType() {
    return RegistrationFieldPreset.REGISTRATION_FORM_LINK_TO_LOGIN
  }

  protected get componentType() {
    return FIELD_COMPONENT_TYPES.RICH_TEXT
  }

  public get role() {
    return FIELDS.ROLE_FIELD_REGISTRATION_FORM_LINK_TO_LOGIN_DIALOG
  }

  protected get skin() {
    return 'wysiwyg.viewer.skins.WRichTextNewSkin'
  }

  protected get layout() {
    return {
      width: 310,
      height: 18,
    }
  }

  protected get props() {
    return {
      required: true,
      type: 'WRichTextProperties',
      isHidden: false,
      brightness: 1.0,
      packed: true,
    }
  }

  protected get data(): any {
    return {
      type: 'StyledText',
      stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
      linkList: [],
      text: this.translate(`fieldTypes.${this.fieldType}.text`),
    }
  }

  public get base() {
    return LinkToLogin.prototype
  }

  public get manifest() {
    return {
      relatedToRole: ROLE_FORM,
      behavior: { duplicatable: false },
    }
  }

  protected get styleProperties() {
    return {
      'properties': {
        'f0': 'font_0',
        'f1': 'font_1',
        'f10': 'font_10',
        'f2': 'font_2',
        'f3': 'font_3',
        'f4': 'font_4',
        'f5': 'font_5',
        'f6': 'font_6',
        'f7': 'font_7',
        'f8': 'font_8',
        'f9': 'font_9',
      },
      'propertiesSource': {
        'f0': 'theme',
        'f1': 'theme',
        'f10': 'theme',
        'f2': 'theme',
        'f3': 'theme',
        'f4': 'theme',
        'f5': 'theme',
        'f6': 'theme',
        'f7': 'theme',
        'f8': 'theme',
        'f9': 'theme',
      },
      'groups': {},
    }
  }
}
