import { BASE_DESIGN_GROUPS } from '@wix/forms-common'

export const baseDesignMappingValues = {
  [BASE_DESIGN_GROUPS.INPUT_BACKGROUND]: ['bg'],
  [BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR]: ['brd'],
  [BASE_DESIGN_GROUPS.INPUT_BORDER_SIZES]: ['brw'],
  [BASE_DESIGN_GROUPS.INPUT_BORDER_SIZES_ERROR]: ['brwe'],
  [BASE_DESIGN_GROUPS.INPUT_BORDER_SIZES_HOVER]: ['brwh'],
  [BASE_DESIGN_GROUPS.INPUT_BORDER_SIZES_FOCUS]: ['brwf'],
  [BASE_DESIGN_GROUPS.INPUT_CORNERS]: ['rd'],
  [BASE_DESIGN_GROUPS.MAIN_TEXT_COLOR]: ['txt'],
  [BASE_DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR]: ['txt2'],
  [BASE_DESIGN_GROUPS.MAIN_TEXT_FONT]: ['fnt'],
  [BASE_DESIGN_GROUPS.LABEL_TEXT_COLOR]: ['txtlbl'],
  [BASE_DESIGN_GROUPS.LABEL_TEXT_FONT]: ['fntlbl'],
  [BASE_DESIGN_GROUPS.INPUT_BACKGROUND_HOVER]: ['bgh'],
  [BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR_HOVER]: ['brdh'],
  [BASE_DESIGN_GROUPS.INPUT_BACKGROUND_FOCUS]: ['bgf'],
  [BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR_FOCUS]: ['brdf'],
  [BASE_DESIGN_GROUPS.INPUT_BACKGROUND_ERROR]: ['bge'],
  [BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR_ERROR]: ['brde'],
  [BASE_DESIGN_GROUPS.LABEL_REQUIRED_TEXT_COLOR]: ['txtlblrq'],
  [BASE_DESIGN_GROUPS.INPUT_SHADOW]: ['shd'],
}

export const baseStyleDefaults = {
  type: 'TopLevelStyle',
  pageId: '',
  compId: '',
  styleType: 'custom',
}
