import _ from 'lodash'
import {
  COMPONENT_TYPES,
  FormsFieldPreset,
  FormPlugin,
  FormPreset,
  SuccessActionTypes,
} from '@wix/forms-common'
import { ROLE_FORM, ROLE_MESSAGE } from '../../../constants/roles'
import successMessageStructure from '../../../assets/presets/hidden-message.json'
import submitButtonStructure from '../../../assets/presets/submit-button.json'
import { skinToStyleMapping, translateContactFormStyle } from './contact-form-style'
import { fieldsStore } from '../preset/fields/fields-store'
import { createSuffixedName } from '../../../utils/utils'
import { enhanceFormWithMobileStructure } from './contact-form-mobile-service'
import { enhanceFormWithDesktopStructure } from './contact-form-layout'
import translations from '../../../utils/translations'

export const getTextInputComponent = (convertedForm: RawComponentStructure) =>
  _.find(convertedForm.components, {
    componentType: COMPONENT_TYPES.TEXT_INPUT,
  })
export const getButtonComponent = (convertedForm: RawComponentStructure) =>
  _.find(convertedForm.components, {
    componentType: COMPONENT_TYPES.SITE_BUTTON,
  })

export type ContactFormEnhancer = (
  contactForm: DynamicContactForm,
  convertedForm: RawComponentStructure,
  fontOptions?,
) => RawComponentStructure

type DynamicFieldEnhancer = (
  contactForm: DynamicContactForm,
  dynamicField: DynamicContactFormField,
  convertedField: RawComponentStructure,
) => RawComponentStructure

const supportedTypes = [COMPONENT_TYPES.DYNAMIC_CONTACT_FORM]
const supportedSkins = ['contactform.FullWidthButtonSkin']
const skinsWithFieldTitles = [
  'wysiwyg.viewer.skins.contactform.VerticalForm',
  'wysiwyg.viewer.skins.contactform.VerticalFormLabelsLeft',
]

export const isSupported = (componentType, skin) =>
  _.includes(supportedTypes, componentType) && _.includes(supportedSkins, skin)
export const isSkinWithFieldTitles = skin => _.includes(skinsWithFieldTitles, skin)

export const convertContactFormToWixForms = (
  contactForm: DynamicContactForm,
  fontOptions,
): RawComponentStructure => {
  const submitButtonComponent: RawComponentStructure = _.merge({}, submitButtonStructure, {
    layout: {
      width: 142,
      height: 40,
      x: 60,
      y: 52,
    },
    data: {
      label: contactForm.data.submitButtonLabel || 'Send',
    },
  })
  const successMessageComponent: RawComponentStructure = _.merge({}, successMessageStructure, {
    layout: {
      width: contactForm.layout.width - 60,
      height: 21,
      x: 60,
      y: 102,
    },
    data: {
      text: `<p class="font_8"><span style="color:#8FCA8F;">${contactForm.data.successMessage ||
        translations.t('contactForm.defaultSuccessMessage')}</span></p>`,
    },
  })
  const dynamicFields = fetchDynamicFields(contactForm)
  const components: RawComponentStructure[] = [
    ...dynamicFields,
    submitButtonComponent,
    successMessageComponent,
  ]
  const baseStructure = {
    type: 'Container',
    components: components,
    skin: 'wysiwyg.viewer.skins.FormContainerSkin',
    layout: {
      x: contactForm.layout.x,
      y: contactForm.layout.y,
      width: contactForm.layout.width,
      height: contactForm.layout.height,
    },
    componentType: COMPONENT_TYPES.FORM_CONTAINER,
    style: {
      type: 'ComponentStyle',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
      },
      style: {
        properties: {
          bg: '#FFFFFF',
          'alpha-bg': 0,
        },
        propertiesSource: {
          bg: 'value',
        },
      },
      styleType: 'custom',
      skin: 'wysiwyg.viewer.skins.FormContainerSkin',
    },
    role: ROLE_FORM,
    config: {
      preset: FormPreset.CONTACT_FORM,
      labels: ['contacts-contacted_me'],
      emailId: '',
      errorMessage: 'Fill in missing fields.',
      secondsToResetForm: 3,
      formName: contactForm.data.formName || 'Contact',
      plugins: [
        {
          id: FormPlugin.FORM_BUILDER,
        },
      ],
      emailIds: [contactForm.data.toEmailAddress, contactForm.data.bccEmailAddress].filter(
        email => email,
      ),
    },
    behaviors: contactForm.behaviors,
  }
  const enhancers: ContactFormEnhancer[] = [
    handleAfterSubmitBehavior,
    hideFormInMobile,
    enhanceWithStyle,
    enhanceFormWithMobileStructure,
    enhanceFormWithDesktopStructure,
    fixDuplicatedCrmLabels,
  ]
  const convertedStructure = enhancers.reduce<RawComponentStructure>(
    (previousStructure, enhancer) => enhancer(contactForm, previousStructure, fontOptions),
    baseStructure,
  )
  return convertedStructure
}

const createBaseField = (contactFormField: DynamicContactFormField) => {
  switch (contactFormField.name) {
    case 'name':
      return fieldsStore.allFieldsData[FormsFieldPreset.FIRST_NAME]
    case 'address':
      return fieldsStore.allFieldsData[FormsFieldPreset.ADDRESS]
    case 'phone':
      return fieldsStore.allFieldsData[FormsFieldPreset.PHONE]
    case 'email':
      return fieldsStore.allFieldsData[FormsFieldPreset.MAIN_EMAIL]
    case 'secondaryEmail':
      return fieldsStore.allFieldsData[FormsFieldPreset.EMAIL]
    case 'message':
      return fieldsStore.allFieldsData[FormsFieldPreset.GENERAL_TEXT_BOX]
    default:
      return fieldsStore.allFieldsData[FormsFieldPreset.GENERAL_TEXT]
  }
}

const updateConfig: DynamicFieldEnhancer = (
  _contactForm: DynamicContactForm,
  _dynamicField: DynamicContactFormField,
  convertedField: RawComponentStructure,
): RawComponentStructure => {
  convertedField.config = { ...(convertedField as any).connectionConfig }
  delete (convertedField as any).connectionConfig
  return convertedField
}

const updateCrmLabel: DynamicFieldEnhancer = (
  _contactForm: DynamicContactForm,
  dynamicField: DynamicContactFormField,
  convertedField: RawComponentStructure,
): RawComponentStructure => {
  convertedField.config.crmLabel =
    dynamicField.displayLabel.substring(0, 25).trim() || dynamicField.name
  return convertedField
}

const updateLabel: DynamicFieldEnhancer = (
  contactForm: DynamicContactForm,
  dynamicField: DynamicContactFormField,
  convertedField: RawComponentStructure,
): RawComponentStructure => {
  if (isSkinWithFieldTitles(contactForm.skin)) {
    convertedField.data.placeholder = ''
    convertedField.props.placeholder = ''
    convertedField.data.label = `${dynamicField.displayLabel}${dynamicField.required ? ' *' : ''}`
  }

  return convertedField
}

const updatePlaceholder: DynamicFieldEnhancer = (
  contactForm: DynamicContactForm,
  dynamicField: DynamicContactFormField,
  convertedField: RawComponentStructure,
): RawComponentStructure => {
  const content = `${dynamicField.displayLabel}${
    dynamicField.required ? ' *' : ''
  }`
  if (!isSkinWithFieldTitles(contactForm.skin)) {
    convertedField.data.label = ''
    convertedField.data.placeholder = content
    convertedField.props.placeholder = content
  }

  return convertedField
}

const updateRequired: DynamicFieldEnhancer = (
  _contactForm: DynamicContactForm,
  dynamicField: DynamicContactFormField,
  convertedField: RawComponentStructure,
): RawComponentStructure => {
  convertedField.props.required = dynamicField.required
  return convertedField
}

const updateTextAlignment: DynamicFieldEnhancer = (
  contactForm: DynamicContactForm,
  _dynamicField: DynamicContactFormField,
  convertedField: RawComponentStructure,
): RawComponentStructure => {
  convertedField.props.textAlignment = contactForm.data.textDirection
  return convertedField
}

const fixDuplicatedCrmLabels: ContactFormEnhancer = (
  _contactForm: DynamicContactForm,
  convertedForm: RawComponentStructure,
) => {
  const crmLabels = []

  _.forEach(convertedForm.components, field => {
    if (!_.includes([COMPONENT_TYPES.TEXT_INPUT, COMPONENT_TYPES.TEXT_AREA], field.componentType)) {
      return
    }

    if (_.includes(crmLabels, field.config.crmLabel)) {
      field.config.crmLabel = createSuffixedName(crmLabels, field.config.crmLabel)
    }

    crmLabels.push(field.config.crmLabel)
  })

  return convertedForm
}

const fieldEnhancers: DynamicFieldEnhancer[] = [
  updateConfig,
  updateCrmLabel,
  updateLabel,
  updatePlaceholder,
  updateRequired,
  updateTextAlignment,
]

const fetchDynamicFields = (contactForm: DynamicContactForm) => {
  return _.map(contactForm.data.dynamicFields, field => {
    const baseField = createBaseField(field).fieldStructure() as RawComponentStructure

    const convertedFieldStructure = fieldEnhancers.reduce<RawComponentStructure>(
      (previousFieldStructure, enhancer) => enhancer(contactForm, field, previousFieldStructure),
      baseField,
    )

    return convertedFieldStructure
  })
}

const hideFormInMobile: ContactFormEnhancer = (
  contactForm: DynamicContactForm,
  structure: RawComponentStructure,
): RawComponentStructure => {
  if (!_.get(contactForm.mobileHints, 'hidden')) {
    return structure
  }
  const hideFormInMobileAction: (
    structure: RawComponentStructure,
  ) => RawComponentStructure = structure => {
    const structureWithMobileHidden: RawComponentStructure = {
      ...structure,
      mobileHints: { hidden: true, type: 'MobileHints' },
    }
    if (structure.components) {
      return {
        ...structureWithMobileHidden,
        components: structureWithMobileHidden.components.map(component =>
          hideFormInMobileAction(component),
        ),
      }
    } else {
      return structureWithMobileHidden
    }
  }
  return hideFormInMobileAction(structure)
}

const handleAfterSubmitBehavior: ContactFormEnhancer = (
  contactForm: DynamicContactForm,
  structure: RawComponentStructure,
): RawComponentStructure => {
  const shouldShowLink = contactForm.data.onSubmitBehavior === 'link'
  const enhancedStructure = _.merge({}, structure, {
    config: {
      successActionType: shouldShowLink ? SuccessActionTypes.LINK : SuccessActionTypes.SHOW_MESSAGE,
      successLinkValue: shouldShowLink ? contactForm.data.link : undefined,
    },
  })
  if (shouldShowLink) {
    enhancedStructure.components = structure.components.filter(
      component => component.role !== ROLE_MESSAGE,
    )
  }
  return enhancedStructure
}

const enhanceWithStyle: ContactFormEnhancer = (
  contactForm: DynamicContactForm,
  structure: RawComponentStructure,
): RawComponentStructure => {
  const skinTranslator = skinToStyleMapping[contactForm.skin]
  const enhanceWithStyleAction: (
    structure: RawComponentStructure,
  ) => RawComponentStructure = structure => {
    const componentTranslatorCreator = _.get(skinTranslator, 'translator')
    const componentTranslator = componentTranslatorCreator
      ? componentTranslatorCreator()[structure.componentType]
      : null
    const componentWithStyle: RawComponentStructure = componentTranslator
      ? translateContactFormStyle(
          componentTranslator,
          skinTranslator.defaultValues,
          contactForm.style,
          structure,
        )
      : structure
    if (structure.components) {
      return {
        ...componentWithStyle,
        components: componentWithStyle.components.map(component =>
          enhanceWithStyleAction(component),
        ),
      }
    } else {
      return componentWithStyle
    }
  }
  return enhanceWithStyleAction(structure)
}
