import { createSuffixedName } from '../../../utils/utils'
import translations from '../../../utils/translations'
import { EVENTS } from '../../../constants/bi'
import _ from 'lodash'
import { isInputField } from '../utils'
import { withBi } from '../decorators'
import { allowCollectionSync } from '../preset/fields/field-types-data'
import { findPlugin } from '../plugins/utils'
import {
  FormPlugin,
  FormPreset,
  FieldCollectionType,
  SUBMISSION_DISPLAY_FIELD,
  PAYMENT_DISPLAY_FIELD,
  TITLE_FIELD,
} from '@wix/forms-common'
import {
  updateSchema,
  createSchema,
  setDisplayName,
  setDisplayField,
  removeSchema,
  addField,
  updateField,
  markFieldDeleted,
  setPermissions,
  PRESETS,
  PERMISSIONS_BY_PRESET,
} from '@wix/wix-code-collections-api'
import { FedopsLogger } from '@wix/fedops-logger'
import Experiments from '@wix/wix-experiments'
import { Schema } from '@wix/wix-code-collections-api/lib/types'

const WIXCODE_APP_DEF_ID = '675bbcef-18d8-41f5-800e-131ec9e08762'

export default class CollectionsApi {
  private fedopsLogger: FedopsLogger
  private collectionsApi: any
  private boundEditorSDK: BoundEditorSDK
  private appDefinitionId: any
  private biLogger: any
  private experiments: Experiments

  constructor(
    boundEditorSDK: any,
    collectionsApi: any,
    appDefinitionId: any,
    { biLogger, fedopsLogger, experiments },
  ) {
    this.collectionsApi = collectionsApi
    this.boundEditorSDK = boundEditorSDK
    this.appDefinitionId = appDefinitionId
    this.biLogger = biLogger
    this.fedopsLogger = fedopsLogger
    this.experiments = experiments
  }

  public async removeCollection(collectionId: string): Promise<void> {
    return this.collectionsApi.execute(removeSchema(collectionId))
  }

  private _installWixCode() {
    if (this._isWixCodeInstalled()) {
      return Promise.resolve()
    }

    return this.boundEditorSDK.document.application.install({
      appDefinitionId: WIXCODE_APP_DEF_ID,
      originInfo: null,
    })
  }

  private _isWixCodeInstalled() {
    try {
      const wixCodeApi = this.boundEditorSDK.document.application.getPublicAPI({
        appDefinitionId: 'wix-code',
      })
      return !!wixCodeApi
    } catch (ex) {
      return false
    }
  }

  public async updateCollectionName(collectionId: string, formName: string): Promise<void> {
    if (collectionId && (await this.isCollectionExists(collectionId))) {
      await this.collectionsApi.execute(
        updateSchema(collectionId, setDisplayName(formName || collectionId)),
      )
    }
  }

  @withBi({
    startEvid: EVENTS.PANELS.settingsPanel.CREATE_SUBMISSIONS_TABLE,
    endEvid: EVENTS.PANELS.settingsPanel.SUBMISSIONS_TABLE_CREATED_SUCCESSFULLY,
  })
  public async createCollection(
    {
      preset,
      fields,
      plugins,
      fieldKeyCallback,
      formName,
    }: {
      preset: FormPreset
      fields: FormField[]
      plugins: ComponentPlugin[]
      fieldKeyCallback: (component: ComponentRef, fieldKey: string) => void
      formName: string
    },
    _biData = {},
  ) {
    await this._installWixCode()

    const collections = await this.collectionsApi.getAll()
    const collectionId = createSuffixedName(_.map(collections, 'id'), _.camelCase(preset), '')

    const formFields = this._addFieldsToCollection(fields, fieldKeyCallback)
    const submissionTimeField = {
      displayName: translations.t(`addForm.submissions.${SUBMISSION_DISPLAY_FIELD}`),
      type: FieldCollectionType.DATETIME,
    }
    const paymentField = {
      displayName: translations.t(`addForm.submissions.${PAYMENT_DISPLAY_FIELD}`),
      type: FieldCollectionType.TEXT,
    }
    const paymentPlugin: ComponentPlugin = findPlugin(plugins, FormPlugin.PAYMENT_FORM)
    const fieldsToAdd = [
      addField(SUBMISSION_DISPLAY_FIELD, submissionTimeField),
      ...formFields,
      paymentPlugin && paymentPlugin.payload ? addField(PAYMENT_DISPLAY_FIELD, paymentField) : null,
    ].filter(field => field)

    await this.collectionsApi.execute(
      createSchema(
        collectionId,
        ...fieldsToAdd,
        setDisplayName(formName || collectionId),
        setDisplayField(SUBMISSION_DISPLAY_FIELD),
        markFieldDeleted(TITLE_FIELD),
        setPermissions(PERMISSIONS_BY_PRESET[PRESETS.FORM_INPUTS]),
      ),
    )

    return collectionId
  }

  private _addFieldsToCollection(
    fields: FormField[],
    fieldKeyCallback?: (component: ComponentRef, fieldKey: string) => void,
  ) {
    const fieldKeys = []
    const fieldsCollectionData = _.filter(
      fields,
      ({ role, fieldType }) => isInputField(role) && allowCollectionSync(fieldType),
    ).map(({ crmLabel, collectionFieldKey, collectionFieldType, componentRef }) => {
      const fieldKey = collectionFieldKey
        ? collectionFieldKey
        : createSuffixedName(fieldKeys, _.camelCase(crmLabel), '')
      if (!collectionFieldKey) {
        fieldKeyCallback(componentRef, fieldKey)
      }

      fieldKeys.push(fieldKey)

      return {
        fieldKey,
        fieldConfig: {
          displayName: crmLabel,
          type: collectionFieldType || FieldCollectionType.TEXT,
        },
      }
    })

    return fieldsCollectionData.map(({ fieldKey, fieldConfig }) => addField(fieldKey, fieldConfig))
  }

  public async addFieldsToCollection(
    collectionId: string,
    fields: FormField[],
    fieldKeyCallback?: (component: ComponentRef, fieldKey: string) => void,
  ) {
    if (!(await this.isCollectionExists(collectionId))) {
      return Promise.resolve()
    }
    return this.collectionsApi.execute(
      updateSchema(collectionId, ...this._addFieldsToCollection(fields, fieldKeyCallback)),
    )
  }

  public async isCollectionExists(collectionId) {
    if (!this._isWixCodeInstalled()) {
      return false
    }

    return _.some(
      await this.collectionsApi.getAll().catch(() => {
        {
        }
      }),
      { id: collectionId },
    )
  }

  public async getCollectionMapById() {
    return _.keyBy(
      await this.collectionsApi.getAll().catch(() => {
        {
        }
      }),
      'id',
    )
  }

  public getSchema(collectionId: string): Promise<Schema> {
    return this.collectionsApi.get(collectionId)
  }

  public async addFieldToCollection(collectionId, fieldConnectionConfig) {
    const { collectionFieldKey, crmLabel, collectionFieldType } = fieldConnectionConfig

    if (!collectionId || !collectionFieldType) {
      return
    }

    try {
      this.fedopsLogger.interactionStarted('add-field-to-collection')
      await this.collectionsApi.execute(
        updateSchema(
          collectionId,
          addField(collectionFieldKey, {
            displayName: crmLabel,
            type: collectionFieldType,
          }),
        ),
      )
      this.fedopsLogger.interactionEnded('add-field-to-collection')
    } catch (err) {
      return Promise.resolve() // [SENTRY] TODO: maybe log with captureMessage?
    }
  }

  public async updateField(collectionId, fieldKey, displayName) {
    if (!(await this.isCollectionExists(collectionId))) {
      return Promise.resolve()
    }

    return this.collectionsApi.execute(
      updateSchema(
        collectionId,
        updateField(fieldKey, {
          displayName,
        }),
      ),
    )
  }

  private async _addField(collectionId, { fieldKey, displayName, type }) {
    if (!(await this.isCollectionExists(collectionId))) {
      return Promise.resolve()
    }

    return this.collectionsApi.execute(
      updateSchema(
        collectionId,
        addField(fieldKey, {
          displayName,
          type,
        }),
      ),
    )
  }

  public addPaymentField(collectionId) {
    return this._addField(collectionId, {
      fieldKey: PAYMENT_DISPLAY_FIELD,
      displayName: translations.t(`addForm.submissions.${PAYMENT_DISPLAY_FIELD}`),
      type: FieldCollectionType.TEXT,
    })
  }
}
